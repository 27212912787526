import {environment} from "@/auth/environment";

export default class EquipmentService {
    // Will be used by this service for making API calls
    axiosIns = null
    useJwt = null
    jwtConfig = null


    constructor(useJwt) {
        this.useJwt = useJwt
        this.jwtConfig = useJwt.jwtConfig
        this.axiosIns = useJwt.axiosIns

    }


    vehicles(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getVehicles/" + company_id, ...args)
    }

    vehicle(vehicle_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getVehicle/" + vehicle_id + "?company_id=" + company_id)
    }

    update_vehicle(vehicle_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updateVehicle/" + vehicle_id, data)
    }

    delete_vehicle(vehicle_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deleteVehicle/" + vehicle_id, data)
    }

    import_vehicles(data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/importVehicles/", data)
    }

    import_plants(data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/importPlants/", data)
    }

    import_equipments(data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/importEquipments/", data)
    }

    vehicle_documents(vehicle_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getVehicleDocuments/" + vehicle_id, ...args)
    }

    vehicle_document(vehicle_document_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getVehicleDocument/" + vehicle_document_id)
    }

    update_vehicle_document(vehicle_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updateVehicleDocument/" + vehicle_document_id, data)
    }

    delete_vehicle_document(vehicle_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deleteVehicleDocument/" + vehicle_document_id, data)
    }

    equipments(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getEquipments/" + company_id, ...args)
    }

    equipment(equipment_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getEquipment/" + equipment_id + "?company_id=" + company_id)
    }

    update_equipment(equipment_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updateEquipment/" + equipment_id, data)
    }

    delete_equipment(equipment_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deleteEquipment/" + equipment_id, data)
    }


    equipment_documents(equipment_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getEquipmentDocuments/" + equipment_id, ...args)
    }

    equipment_document(equipment_document_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getEquipmentDocument/" + equipment_document_id)
    }

    update_equipment_document(equipment_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updateEquipmentDocument/" + equipment_document_id, data)
    }

    delete_equipment_document(equipment_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deleteEquipmentDocument/" + equipment_document_id, data)
    }


    plants(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getPlants/" + company_id, ...args)
    }

    plant(plant_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getPlant/" + plant_id + "?company_id=" + company_id)
    }

    update_plant(plant_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updatePlant/" + plant_id, data)
    }

    delete_plant(plant_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deletePlant/" + plant_id, data)
    }


    plant_documents(plant_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getPlantDocuments/" + plant_id, ...args)
    }

    plant_document(plant_document_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/equipments/getPlantDocument/" + plant_document_id)
    }

    update_plant_document(plant_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/updatePlantDocument/" + plant_document_id, data)
    }

    delete_plant_document(plant_document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/equipments/deletePlantDocument/" + plant_document_id, data)
    }


}
