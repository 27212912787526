import {environment} from "@/auth/environment";

export default class UserService {
    // Will be used by this service for making API calls
    axiosIns = null
    useJwt = null
    jwtConfig = null


    constructor(useJwt) {
        this.useJwt = useJwt
        this.jwtConfig = useJwt.jwtConfig
        this.axiosIns = useJwt.axiosIns

    }


    profile(...args) {
        return this.axiosIns.post(this.jwtConfig.profileEndpoint, ...args)
    }

    users(company_id) {
        return this.axiosIns.get(environment.baseURL + "/company/getUsersByCompany/" + company_id)
    }

    getUserById(user_id, company_id) {
        return this.axiosIns.get(environment.baseURL + "/company/getUserById/" + user_id + "/" + company_id)
    }


    document_sections(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/documents/getSections/" + company_id, ...args)
    }

    document_sections_filtered(company_id, moved_section, ...args) {
        return this.axiosIns.get(environment.baseURL + "/documents/getSectionsWithoutDescendants/" + company_id + "/" + moved_section, ...args)
    }

    document_sections_tree(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/documents/getSectionsAsTree/" + company_id, ...args)
    }

    companies(...args) {
        return this.axiosIns.get(environment.baseURL + "/company/index", ...args)
    }

    company(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/company/get/" + company_id, ...args)
    }

    update_company(company_id, data) {
        return this.axiosIns.post(environment.baseURL + "/company/update/" + company_id, data)
    }

    delete_company(company_id, data) {
        return this.axiosIns.post(environment.baseURL + "/company/delete/" + company_id, data)
    }

    update_user(user_id, data) {
        return this.axiosIns.post(environment.baseURL + "/company/update_user/" + user_id, data)
    }


    delete_user(user_id, data) {
        return this.axiosIns.post(environment.baseURL + "/company/delete_user/" + user_id, data)
    }

    document_section(section_id) {
        return this.axiosIns.get(environment.baseURL + "/documents/getSection/" + section_id)
    }

    update_document_section(section_id, ...args) {
        return this.axiosIns.post(environment.baseURL + "/documents/updateSection/" + section_id, ...args)
    }

    delete_document_section(section_id, ...args) {
        return this.axiosIns.post(environment.baseURL + "/documents/deleteSection/" + section_id, ...args)
    }

    documents(document_section_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/documents/list/" + document_section_id, ...args)
    }

    document(document_id) {
        return this.axiosIns.get(environment.baseURL + "/documents/" + document_id)
    }

    update_document(document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/documents/updateDocument/" + document_id, data)
    }

    delete_document(document_id, data) {
        return this.axiosIns.post(environment.baseURL + "/documents/deleteDocument/" + document_id, data)
    }

    upload_attachment(company_id, formData) {
        return this.axiosIns.post(environment.baseURL + "/documents/upload_attachment/" + company_id, formData)
    }

    share_attachment(attachment_id) {
        return this.axiosIns.get(environment.baseURL + "/documents/share/" + attachment_id)
    }

    files_tree(company_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/files/getFilesAsTree/" + company_id, ...args)
    }

    upload_file(company_id, formData) {
        return this.axiosIns.post(environment.baseURL + "/files/uploadFile/" + company_id, formData)
    }

    create_folder(company_id, formData) {
        return this.axiosIns.post(environment.baseURL + "/files/createFolder/" + company_id, formData)
    }

    update_file(file_id, formData) {
        return this.axiosIns.post(environment.baseURL + "/files/updateFile/" + file_id, formData)
    }

    move_file(file_id, formData) {
        return this.axiosIns.post(environment.baseURL + "/files/moveFile/" + file_id, formData)
    }

    delete_file(file_id, formData) {
        return this.axiosIns.get(environment.baseURL + "/files/deleteFile/" + file_id, formData)
    }

    get_files_comments(file_id, ...args) {
        return this.axiosIns.get(environment.baseURL + "/files/getFileComments/" + file_id, ...args)
    }

    add_file_comment(file_id, ...args) {
        return this.axiosIns.post(environment.baseURL + "/files/addFileComment/" + file_id, ...args)
    }

    forgot_password(data) {
        return this.axiosIns.post(environment.baseURL + "/forgotPassword/", data)
    }

    check_token(data) {
        return this.axiosIns.post(environment.baseURL + "/checkToken/", data)
    }

    reset_password(data) {
        return this.axiosIns.post(environment.baseURL + "/resetPassword/", data)
    }

}
