<template>


  <b-card-actions ref="list" title="" action-refresh @refresh="refresh">

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <export-excel
              :data="rows"
              :columns="excel_columns"
              :file-name="'document_sections'"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
          >
            <feather-icon icon="DownloadIcon"/>
          </export-excel>

          <b-button
              variant="primary"
              class="button-inline-spacing-2"
              @click="add_personalProtectiveEquipment()"
              :disabled="editDisabled"
          >
            <span class="text-nowrap">{{ $t("Add") }}</span>
          </b-button>

        </b-col>
        <b-col
            cols="12"
            md="4"
        >

        </b-col>
        <!-- Search -->
        <b-col
            cols="12"
            md="4"
        >

          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('message.SearchPlaceholder')"
              type="text"
              class="d-inline-block"
          />
        </b-col>
      </b-row>

    </div>


    <!-- alert -->
    <!--b-alert
      variant="primary"
      show
    >
      <div class="alert-body">
      </div>
    </b-alert-->

    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">


    </div>

    <!-- table -->
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :select-options="{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >

      <!-- Slot: Table Column -->
      <template
          slot="table-column"
          slot-scope="props"
      >

        <span>
           {{ $t(props.column.label) }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
          slot="table-row"
          slot-scope="props"
      >

        <!-- Column: Icon -->
        <span
            v-if="props.column.field === 'icon'"
            class="text-nowrap v-align-middle"
        >
          <span><img :src="props.row.icon" class="mr-8 rounded h-24 w-24 profile-pic-list"/></span>
        </span>


        <!-- Column: Name -->
        <span
            v-else-if="props.column.field === 'name'"
            class="text-nowrap align-middle"
        >
                    <span>{{ props.row.name }}</span>

        </span>


        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'" class="button-inline-spacing">

          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon"
              @click="edit(props.row.id)"

          >
      <feather-icon icon="Edit2Icon"/>
    </b-button>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="deletePersonalProtectiveEquipment(props.row)"
                :disabled="editDisabled"
            >
      <feather-icon icon="TrashIcon"/>
    </b-button>


        </span>

        <span v-else class="text-nowrap align-middle">


        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >

        <div class="d-flex justify-content-between flex-wrap">


          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['3','5','10','15']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>

            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>

            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <template #code>
    </template>
  </b-card-actions>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {BCardActions} from "@core/components/b-card-actions";
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BAlert, BDropdown, BDropdownItem, BRow, BCol, BFormCheckbox,

} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import {codeI18n} from './code'
import useJwt from '@/auth/jwt/useJwt'
import UserService from "@/basic/userService";
import EquipmentService from "@/basic/equipmentService";
import Ripple from 'vue-ripple-directive'

import {BButton} from "bootstrap-vue";
import ExportExcel from "@/views/components/excel-export/ExportExcel";

import Vue from "vue";
import i18n from "@/libs/i18n";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ability from "@/libs/acl/ability";
import EmployeeService from "@/basic/employeeService";

Vue.use(ExportExcel);

export default {
  components: {
    BCardCode,
    BCardActions,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      pageLength: 15,
      codeI18n,
      dir: false,
      editDisabled: !ability.can("manage", "Basic HR"),
      columns: [
        {
          label: 'Icon',
          field: 'icon',
        },
        {
          label: 'Name',
          field: 'name',
        },

        {
          label: 'action',
          field: 'action',
        },

      ],
      excel_columns: [
        {
          label: i18n.t('Name'),
          field: 'name',
        },


      ],
      rows: [],
      searchTerm: '',
      show_terminated: false,
      terminated_label: i18n.t("Show terminated"),
      personalProtectiveEquipments_terminated: [],
      personalProtectiveEquipments: [],

    }
  },
  watch: {
    show_terminated: {
      handler: function (newValue) {
        console.log('watch show_terminated:' + newValue);
        if (newValue) {
          console.log('terminated_personalProtectiveEquipments', this.personalProtectiveEquipments_terminated)

          this.rows = this.personalProtectiveEquipments_terminated
        } else {
          console.log('personalProtectiveEquipments', this.personalProtectiveEquipments)
          this.rows = this.personalProtectiveEquipments
        }
        console.log(this.rows)
      },
      immediate: true,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {
    refresh() {
      console.log("refresh")
      this.$refs["list"].showLoading = true


      let company_id = store.state.userCompany.id;
      const employeeService = new EmployeeService(useJwt)

      employeeService.ppes(company_id, {}).then(response => {
        this.rows = response.data.data.ppes
        this.ppes = response.data.data.ppes
        console.log('rows', this.rows)
        this.$refs["list"].showLoading = false

      })

    },


    add_personalProtectiveEquipment() {
      this.$router.push({name: 'ppe', params: {id: 'new'}})
    },
    import_bulk() {
      this.$router.push({name: 'import-personalProtectiveEquipments', params: {}})
    },
    edit(id) {
      this.$router.push({name: 'ppe', params: {id: id}})
    },
    deletePersonalProtectiveEquipment(row) {
      console.log('delete')
      let confirmed = false;
      let message = i18n.t('You are deleting the personalProtectiveEquipment') + ": \"" + row.name  + "\". " + i18n.t("Are you sure?");

      this.$bvModal
          .msgBoxConfirm(message, {
            title: i18n.t('Confirmation Required'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: i18n.t('Proceed'),
            cancelTitle: i18n.t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            confirmed = value

            if (confirmed) {
              const employeeService = new EmployeeService(useJwt)

              employeeService.delete_ppe(row.id).then(response => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: i18n.t('PersonalProtectiveEquipment deleted'),
                    icon: 'AlertIcon',
                    variant: 'success',
                    text: i18n.t('PersonalProtectiveEquipment deleted with success'),
                  },
                })
                this.refresh();
              })
            }
          })

    },
    list_documents(id) {
      this.$router.push({name: 'documents', params: {document_section_id: id}})
    },
  },
  mounted() {
    const equipmentService = new EquipmentService(useJwt)


    this.$refs["list"].showLoading = true

    this.unsubscribe = store.subscribe((mutation, state) => {
      if (mutation.type === 'selected_company') {
        console.log(`Updating selected_company to ${state.userCompany.id}`);
        this.refresh();
      }
    });


    this.refresh();
    //this.$http.get('/good-table/basic')
    //.then(res => { this.rows = res.data })
  },
  beforeDestroy() {
    this.unsubscribe();
  }
}
</script>
