<template>
  <b-row>
    <b-col cols="12">
      <p-p-e-list-component/>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import GoodTableBasic from './GoodTableBasic.vue'
import GoodTableRowGroup from './GoodTableRowGroup.vue'
import GoodTableColumnSearch from './GoodTableColumnSearch.vue'
import GoodTableAdvanceSearch from './GoodTableAdvanceSearch.vue'
import EmployeesListComponent from "@/views/table/vue-good-table/EmployeesListComponent";
import PPEListComponent from "@/views/table/vue-good-table/PPEListComponent";
import GoodTableSsr from './GoodTableSsr.vue'

export default {
  components: {
    PPEListComponent,
    BRow,
    BCol,

    GoodTableBasic,
    GoodTableRowGroup,
    GoodTableColumnSearch,
    GoodTableAdvanceSearch,
    EmployeesListComponent,
    GoodTableSsr,
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
